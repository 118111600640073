import React, { forwardRef } from 'react';
import classNames from 'classnames';

export const Suggestions = forwardRef(({ suggestions, onSelect, className, ...remainingProps }, ref) => (
	<div {...remainingProps} className={classNames('Suggestions', className)}>
		<ol ref={ref}>
			{suggestions.map(({ key, description }) => {
				const handleSuggestionSelect = () => {
					onSelect(key);
				};

				const newProps = {
					onClick: handleSuggestionSelect,
					className: 'Suggestion',
					key,
				}

				return (
					<li {...newProps}>
						<span className="Suggestion-Description">{description}</span>
						<span className="Suggestion-Key">{`{${key}}`}</span>
					</li>
				)}
			)}
		</ol>
	</div>
));
