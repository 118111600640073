import React, { createContext } from 'react';

export const MetaDataContext = createContext();

export const MetaDataProvider = ({ children }) => {
	const data = [
		{ key: 'Source Name', description: 'Source Name or Label' },
		{ key: 'Source Counter', description: 'Counter' },
		{ key: 'File Name', description: 'Name of the file' },
		{ key: 'File Counter', description: 'File Counter' },
		{ key: 'YYYY', description: 'Year' },
		{ key: 'YY', description: 'Year' },
		{ key: 'MM', description: 'Month' },
		{ key: 'DD', description: 'Day' },
		{ key: 'hh', description: 'Hour (24h)' },
		{ key: 'mm', description: 'Minutes' },
		{ key: 'ss', description: 'Seconds' },
		{ key: 'File YYYY', description: 'File Creation Year' },
		{ key: 'File YY', description: 'File Creation Year' },
		{ key: 'File MM', description: 'File Creation Month' },
		{ key: 'File DD', description: 'File Creation Day' },
		{ key: 'File hh', description: 'File Creation Hour (24h)' },
		{ key: 'File mm', description: 'File Creation Minutes' },
		{ key: 'File ss', description: 'File Creation Seconds' },
		{ key: 'File Extension', description: 'File Extension' },
	];

	const addUserData = (cid, key) => {
		const index = data.findIndex((entry) => entry?.id === cid);

		if (index !== -1) {
			if (key === '') {
				delete data[index];
			} else {
				data[index] = { id: cid, key, description: key };
			}
		} else if (key !== '') {
			data.push({ id: cid, key, description: key });
		}
	};

	return <MetaDataContext.Provider value={{ addUserData, items: data }}>{children}</MetaDataContext.Provider>;
};
