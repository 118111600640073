import React from 'react';
import { render } from 'react-dom';
import { App } from '~/components/App';

import { MetaDataProvider } from '~/contexts/MetaDataContext';

render(
	<MetaDataProvider>
		<App />
	</MetaDataProvider>,
	document.getElementById('container'),
);
