import React, { forwardRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

export const VBox = forwardRef(({ children, className, ...remainingProps }, ref) => (
	<StyledBox ref={ref} {...remainingProps} className={classnames('VBox', className)}>
		{children}
	</StyledBox>
));

const StyledBox = styled.div`
	display: flex;
	flex-direction: column;
`;
