import React from "react";
import classNames from "classnames";
import { useSelected } from "slate-react";

export const SuggestionElement = ({ element, attributes, children }) => {
    const selected = useSelected();

    const className = classNames("SuggestionElement", {
        "SuggestionElement--selected": selected,
    });

    // prettier-ignore
    return (
        <span {...attributes} contentEditable={false} className={className}>
            {element.suggestion} 
            {children}
        </span>
);
};
