import React, { useMemo, useState, useContext } from 'react';
import classNames from 'classnames';
import { createEditor, Transforms } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import { MetaDataContext } from '~/contexts/MetaDataContext';

import { Suggestions } from './autocomplete/Suggestions';
import { IconButton } from '../buttons/IconButton';
import { VBox } from '../layout/VBox';
import { Label } from '../typo/Label';
import { SuggestionElement } from './autocomplete/SuggestionElement';
import { DefaultElement } from './autocomplete/DefaultElement';
import { useClickOutside, useEscKey } from 'ui/hooks';

const withCustomElements = (editor) => {
	const { isInline, isVoid } = editor;

	editor.isInline = (element) => (element.type === 'highlightedText' ? true : isInline(element));
	editor.isVoid = (element) => (element.type === 'highlightedText' ? true : isVoid(element));

	return editor;
};

export const AutoComplete = ({ name, label, suggestions, onChange, className }) => {
	const editor = useMemo(() => withCustomElements(withReact(createEditor())), []);
	const suggestionsRef = useClickOutside(() => setShowSuggestions(false));

	const [cursorPosition, setCursorPosition] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);

	const [parsedValue, setParsedValue] = useState('');
	const [value, setValue] = useState([
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	]);

	useEscKey(() => setShowSuggestions(false));

	const handleValueChange = (value) => {
		const parsedValue = value
			.reduce(
				(prevValue, nextValue) =>
					prevValue +
					nextValue.children.reduce((prevChild, nextChild) => {
						if (nextChild.type === 'highlightedText') {
							return prevChild + nextChild.code;
						}

						return prevChild + nextChild.text;
					}, ''),
				'',
			)
			.trim();

		setValue(value);
		setParsedValue(parsedValue);

		onChange?.(parsedValue);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const handleKeyUp = ({ key }) => {
		if (key === 'Backspace') {
			setShowSuggestions(false);
		}
	};

	const handleAddButton = (event) => {
		// Show suggestions
		setCursorPosition(editor.selection);
		setShowSuggestions(true);
	};

	const handleSuggestion = (suggestion) => {
		const highlightedText = {
			type: 'highlightedText',
			children: [{ text: '' }],
			code: `{${suggestion}}`,
			suggestion,
		};

		// Restore previous cursor position
		if (cursorPosition) {
			Transforms.select(editor, cursorPosition);
		}

		// Insert the new node, move the cursor to the end of the node and insert an extra space
		Transforms.insertNodes(editor, highlightedText);
		Transforms.move(editor);

		// Give focus back to the editor
		ReactEditor.focus(editor);
		setShowSuggestions(false);
	};

	const renderElement = (props) => {
		switch (props.element.type) {
			case 'highlightedText':
				return <SuggestionElement {...props} />;

			default:
				return <DefaultElement {...props} />;
		}
	};

	return (
		<VBox className={classNames('AutoComplete', className)}>
			{label && <Label>{label}</Label>}
			<input type="hidden" name={name} value={parsedValue} />

			<Slate editor={editor} value={value} onChange={handleValueChange}>
				<Editable renderElement={renderElement} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="AutoComplete-Input" />
				<IconButton icon="add" onClick={handleAddButton} className="AutoComplete-AddButton" />

				{showSuggestions && (
					<Suggestions ref={suggestionsRef} suggestions={suggestions} onSelect={handleSuggestion} className="AutoComplete-Suggestions" />
				)}
			</Slate>
		</VBox>
	);
};
