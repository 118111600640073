import React from "react";
import classNames from "classnames";
import { useSelected } from "slate-react";

export const TagElement = ({ element, attributes, children }) => {
    const selected = useSelected();

    const className = classNames("TagElement", {
        "TagElement--selected": selected,
    });

    // prettier-ignore
    return (
        <span {...attributes} contentEditable={false} className={className}>
            {children}
            {element.tag}
        </span>
);
};
