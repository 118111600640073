export const normalizeData = (data) => {
	// Convert copyfiles option
	if (data.copyFilesOption === 'doNotCopyFiles') {
		data.doNotCopyFiles = !!data.copyFiles ? data.copyFiles.split(', ') : [];
	} else {
		data.copyOnlyFiles = !!data.copyFiles ? data.copyFiles.split(', ') : [];
	}

	data.ignoreBundles = !!data.ignoreBundles ? data.ignoreBundles.split(', ') : [];

	// Convert folder options
	data.flattenFolders = data.ignoreEmptyFolders === 'flattenFolders';
	data.ignoreEmptyFolders = data.ignoreEmptyFolders === 'ignoreEmptyFolders';

    // Remove empty elements
	data.sourceReviewElements = data.sourceReviewElements.filter((element) => !!element.name) || [];
	data.clipReviewElements = data.clipReviewElements.filter((element) => !!element.name) || [];

	// Iconik
	data.iconikSidecarElements = !!data.iconikSidecarElements ? data.iconikSidecarElements.match(/{[\w\s]*}/g) : [];
	data.iconikSidecarExtensions = !!data.iconikSidecarExtensions ? data.iconikSidecarExtensions.split(', ') : [];
	data.iconikSidecarDestinations = !!data.iconikSidecarDestinations ? data.iconikSidecarDestinations.split(', ') : [];

	// Delete unnessecary keys
	delete data.copyFilesOption;
	delete data.copyFiles;

	return data;
};
