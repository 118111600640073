import React, { useRef } from 'react';
import serialize from 'form-serialize';
import { saveAs } from 'file-saver';

import { normalizeData } from '~/helpers/export/normalizeData';

import { SourceReview } from './builder/SourceReview';
import { GeneralInfo } from './builder/GeneralInfo';
import { ClipReview } from './builder/ClipReview';
import { Settings } from './builder/Settings';
import { Sidecar } from './builder/Sidecar';
import { PrimaryButton } from 'ui/components/buttons/PrimaryButton';

export const App = () => {
	const formRef = useRef();

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	const handleDownload = (event) => {
		let data = serialize(formRef.current, { hash: true, empty: true });
		data = normalizeData(data);

		const dataString = JSON.stringify([data], null, 4);
		const filename = `${(data.name || 'Hedge Preset')}.hedge`;
		const blob = new Blob([dataString], { type: 'application/json;charset=utf-8' });

		saveAs(blob, filename);
	};

	return (
		<section className="App">
			<form id="form" ref={formRef} onSubmit={handleSubmit}>
				<GeneralInfo />
				<SourceReview />
				<ClipReview />
				<Settings />
				<Sidecar />
			</form>

			<footer className="App-Footer">
				<PrimaryButton onClick={handleDownload} form="form">
					Download Preset
				</PrimaryButton>
			</footer>
		</section>
	);
};
