import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { createEditor, Editor, Range, Transforms } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { VBox } from '../layout/VBox';

import { DefaultElement } from './taginput/DefaultElement';
import { TagElement } from './taginput/TagElement';
import { Label } from '../typo/Label';

const withCustomElements = (editor) => {
	const { isInline, isVoid } = editor;

	editor.isInline = (element) => (element.type === 'tag' ? true : isInline(element));
	editor.isVoid = (element) => (element.type === 'tag' ? true : isVoid(element));

	return editor;
};

export const TagInput = ({ name, label, isFile, placeholder, className, ...remainingProps }) => {
	const editor = useMemo(() => withCustomElements(withReact(createEditor())), []);

	const [parsedValue, setParsedValue] = useState('');
	const [value, setValue] = useState([
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	]);

	const handleValueChange = (value) => {
		const parsedValue = value[0].children
			.filter((element) => element.type === 'tag')
			.map((element) => element.tag)
			.join(', ');

		setValue(value);
		setParsedValue(parsedValue);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || (isFile && event.key === ' ')) {
			event.preventDefault();
			insertTag();
		}
	};

	const handleBlur = () => {
		insertTag();
	};

	const renderElement = (props) => {
		switch (props.element.type) {
			case 'tag':
				return <TagElement {...props} />;

			default:
				return <DefaultElement {...props} />;
		}
	};

	const insertTag = () => {
		if (Range.isCollapsed(editor.selection)) {
			const [cursorPosition] = Range.edges(editor.selection);

			// Get the current typed word without prefix
			const wordStartPosition = isFile ? Editor.before(editor, cursorPosition, { unit: 'word' }) : Editor.before(editor, cursorPosition, { unit: 'line' });
			const wordRange = wordStartPosition && Editor.range(editor, wordStartPosition, cursorPosition);
			const currentWord = wordRange && Editor.string(editor, wordRange);

			// Get the current word with prefix
			const prefixStartPosition = Editor.before(editor, wordStartPosition);
			const prefixRange = prefixStartPosition && Editor.range(editor, prefixStartPosition, cursorPosition);
			const prefixedWord = prefixRange && Editor.string(editor, prefixRange);

			// Get the range and word we are interested in
			const range = prefixedWord && !prefixedWord?.startsWith(' ') ? prefixRange : wordRange;
			const checkedWord = prefixedWord && !prefixedWord?.startsWith(' ') ? prefixedWord : currentWord;

			// add a dot if it is a file extensions list
			const word = isFile && !checkedWord.startsWith('.') ? '.' + checkedWord : checkedWord;

			// Let's add the tag
			if (word && word !== ' ' && word !== '. ') {
				// trim space
				const trimmedWord = word.startsWith(' ') ? word.substring(1) : word;

				const tagElement = {
					type: 'tag',
					children: [{ text: '' }],
					tag: trimmedWord,
				};

				Transforms.select(editor, range);
				Transforms.insertNodes(editor, tagElement);
				Transforms.move(editor);
				Transforms.insertText(editor, ' ');
			}
		}
	};

	return (
		<VBox {...remainingProps} className={classNames('TagInput', className)}>
			{label && <Label>{label}</Label>}
			<input type="hidden" name={name} value={parsedValue} />

			<Slate editor={editor} value={value} onChange={handleValueChange}>
				<Editable
					renderElement={renderElement}
					placeholder={placeholder}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
					className="TagInput-Input"
				/>
			</Slate>
		</VBox>
	);
};
