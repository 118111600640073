import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { VBox } from '../layout/VBox';
import { Label } from '../typo/Label';

export const TextArea = ({ label, value, className, editable = false, ...remainingProps }) => {
	const inputRef = useRef();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (inputRef.current) {
			setHeight(inputRef.current.scrollHeight);
		}
	}, [inputRef, value, height]);

	const handleChange = () => {
		setHeight(0);
	};

	const textProps = {
		ref: inputRef,
		onChange: handleChange,
		className: 'TextArea',
		height,
		value,
		...remainingProps,
	};

	return (
		<VBox className={className}>
			{label && <Label>{label}</Label>}
			<StyledTextArea {...textProps}></StyledTextArea>
		</VBox>
	);
};

export const StyledTextArea = styled.textarea`
	${(props) => css`
		height: ${props.height}px;
	`}
`;
