import React, { memo, useState } from 'react';

import { AutoComplete } from 'ui/components/form/AutoComplete';
import { HBox } from 'ui/components/layout/HBox';

export const SelectOption = memo(({ index, name, label, checked, suggestions, ...remainingProps }) => {
	const [value, setValue] = useState('');

	const handleChange = (value) => {
		setValue(value);
	};

	// prettier-ignore
	return (
		<HBox className="SelectOption" {...remainingProps}>
			<input type="hidden" name={`${name}[options][${index}][name]`} value={value} />
			<AutoComplete name={`${name}[options][${index}][value]`} label={label} suggestions={suggestions} onChange={handleChange} />
			<input type="radio" name={`${name}[defaultValue]`} value={value} defaultChecked={checked} title="Default value"/>
		</HBox>
	);
});
