import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { Label, Alert } from '../typo';
import { VBox } from '../layout';
import { Search } from '../icons/Search';

export const TextInput = forwardRef(({ name, value, label, error, icon, onChange, className, ...remainingProps }, ref) => {
	const [errorMessage, setErrorMessage] = useState(error);

	useEffect(() => {
		setErrorMessage(error);
	}, [error]);

	const handleChange = (event) => {
		setErrorMessage();

		if (typeof onChange === 'function') {
			onChange(event);
		}
	};

	const inputProps = {
		type: 'text',
		autoComplete: 'off',
		defaultValue: value,
		className: classnames('TextInput', { 'TextInput--hasError': errorMessage }),
		onChange: handleChange,
		name,
		ref,
		...remainingProps,
	};

	const renderIcon = useCallback(() => {
		switch (icon) {
			case 'search':
				return <Search />;
		}
	}, [icon]);

	return (
		<VBox className={className}>
			{label && <Label>{label}</Label>}

			<input {...inputProps} />
			{errorMessage && <Alert>{errorMessage}</Alert>}
			{renderIcon()}
		</VBox>
	);
});
