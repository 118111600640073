import React, { useContext, useEffect, useState } from 'react';
import { uid } from 'uid';

import { Select } from 'ui/components/form/Select';
import { HBox } from 'ui/components/layout/HBox';

import { MetaDataContext } from '~/contexts/MetaDataContext';

import { CounterElement } from './elements/CounterElement';
import { DateElement } from './elements/DateElement';
import { HiddenElement } from './elements/HiddenElement';
import { SelectElement } from './elements/SelectElement';
import { TextElement } from './elements/TextElement';

export const Element = ({ index, name }) => {
	const { addUserData, items } = useContext(MetaDataContext);

	const [cid, setCid] = useState();
	const [type, setType] = useState('text');
	const [hideLabels, setHideLabels] = useState(true);

	const indexedName = `${name}[${index}]`;

	useEffect(() => {
		setCid(uid());
	}, []);

	useEffect(() => {
		setHideLabels(index > 0);
	}, [index]);

	const handleChange = (value) => {
		addUserData(cid, value);
	};

	const elementProps = {
		suggestions: items,
		name: indexedName,
		onChange: handleChange,
		hideLabels,
	};

	return (
		<HBox className="Element">
			<Select label={!hideLabels ? 'Type' : null} name={`${indexedName}[type]`} onSelect={setType} className="Element-Type">
				<Select.Option value="text" selected>Text</Select.Option>
				<Select.Option value="counter">Counter</Select.Option>
				<Select.Option value="date">Date</Select.Option>
				<Select.Option value="select">Select</Select.Option>
				<Select.Option value="hidden">Hidden</Select.Option>
			</Select>

			{type === 'text' && <TextElement {...elementProps} />}
			{type === 'counter' && <CounterElement {...elementProps} />}
			{type === 'date' && <DateElement {...elementProps} />}
			{type === 'select' && <SelectElement {...elementProps} />}
			{type === 'hidden' && <HiddenElement {...elementProps} />}
		</HBox>
	);
};
