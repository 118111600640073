import React, { useState } from 'react';

import { SimpleButton } from 'ui/components/buttons/SimpleButton';
import { Panel } from 'ui/components/layout/Panel';
import { VBox } from 'ui/components/layout/VBox';
import { Element } from './reviews/Element';

export const SourceReview = () => {
	let [numberOfElements, setNumberOfElements] = useState(1);

	const handleAddButton = () => {
		setNumberOfElements(numberOfElements + 1);
	};

	const elements = (() => {
		const elements = [];
		for (let index = 0; index < numberOfElements; index++) {
			elements.push(<Element key={index} index={index} name="sourceReviewElements" />);
		}

		return elements;
	})();

	return (
		<Panel className="SourceReview">
			<h1>Elements</h1>

			<VBox className="SourceReview-Elements">
				{elements}
			</VBox>

			<SimpleButton onClick={handleAddButton} className="SourceReview-AddElementButton">
				Add new row
			</SimpleButton>
		</Panel>
	);
};
