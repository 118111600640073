import React, { useCallback } from 'react';
import classnames from 'classnames';

import { motion } from 'framer-motion';
import { CircleDelete } from '../icons/CircleDelete';
import { Delete } from '../icons/Delete';
import { Hamburger } from '../icons/Hamburger';
import { Add } from '../icons/Add';

export const IconButton = ({ icon, iconColor = 'blue', className, ...remainingProps }) => {
	const buttonProps = {
		type: 'button',
		whileHover: 'hover',
		className: classnames('IconButton', `IconButton--${iconColor}`, className),
		...remainingProps,
	};

	const renderIcon = useCallback(() => {
		switch (icon) {
			case 'add':
				return <Add />;

			case 'circleDelete':
				return <CircleDelete />;

			case 'hamburger':
				return <Hamburger />;

			case 'delete':
				return <Delete />;
		}
	}, [icon]);

	return <motion.button {...buttonProps}>{renderIcon()}</motion.button>;
};
