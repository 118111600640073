import React from 'react';

import { TextArea } from 'ui/components/form/TextArea';
import { TextInput } from 'ui/components/form/TextInput';
import { Panel } from 'ui/components/layout/Panel';
import { Text } from 'ui/components/typo/Text';

export const GeneralInfo = () => (
	<Panel className="GeneralInfo">
		<h1>Hedge Preset Builder</h1>
		
		<Text>
			{"A Hedge Pro license is needed to use presets built with this form. <a href='https://hedge.video/app/hedge/preset-builder/docs/' target='_blank'>Learn more...</a>"}
		</Text>

		<TextInput name="name" label="Preset name" autoComplete="off" className="GeneralInfo-Name" />
		<TextArea name="description" label="Description" className="GeneralInfo-Description" />

		<input type="hidden" name="version" value="1.0" />
	</Panel>
);
