import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

export const Text = ({ children, className, emphasized = false, pre = false, ...remainingProps }) => {
	console.log(children);
	return (
		<StyledText
			pre={pre}
			emphasized={emphasized}
			className={classnames('Text', className)}
			dangerouslySetInnerHTML={{ __html: children }}
			{...remainingProps}></StyledText>
	);
};

const StyledText = styled.p`
	${(props) => css`
		white-space: ${props.pre ? 'pre-line' : 'normal'};
		font-weight: ${props.emphasized ? 500 : 400};
	`}
`;
