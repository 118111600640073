import React, { useState } from 'react';

import { SimpleButton } from 'ui/components/buttons/SimpleButton';
import { AutoComplete } from 'ui/components/form/AutoComplete';
import { TextInput } from 'ui/components/form/TextInput';
import { HBox } from 'ui/components/layout/HBox';
import { VBox } from 'ui/components/layout/VBox';
import { SelectOption } from './selectelement/SelectOption';

export const SelectElement = ({ name, hideLabels, suggestions, onChange, ...remainingProps }) => {
	const [numberOfOptions, setNumberOfOptions] = useState(1);
	const [parsedName, setParsedName] = useState();

	const handleChange = (event) => {
		const name = `{${event.currentTarget.value}}`;
		setParsedName(name);
	};

	const handleBlur = (event) => {
		onChange(event.target.value);
	};

	const handleAddOption = () => {
		setNumberOfOptions(numberOfOptions + 1);
	};

	const options = (() => {
		const options = [];
		for (let index = 0; index < numberOfOptions; index++) {
			const checked = index === 0;

			let label;
			if (!hideLabels && index === 0) {
				label = 'Default value';
			}

			options.push(
				<SelectOption 
					key={index} 
					index={index} 
					name={name} 
					label={label} 
					suggestions={suggestions} 
					checked={checked} 
				/>
			);
		}

		return options;
	})();

	// prettier-ignore
	return (
		<HBox {...remainingProps} className="SelectElement">
			<input type="hidden" name={`${name}[name]`} defaultValue={parsedName} />

			<TextInput 
				label={!hideLabels ? 'Name' : null} 
				onChange={handleChange}
				onBlur={handleBlur} 
				className="SelectElement-Name" />

			<VBox>
				{options}
				<SimpleButton onClick={handleAddOption} className="SelectElement-AddOptionButton">
					Add option
				</SimpleButton>
			</VBox>
		</HBox>
	);
};
