import React, { useState } from 'react';

import { AutoComplete } from 'ui/components/form/AutoComplete';
import { TextInput } from 'ui/components/form/TextInput';
import { HBox } from 'ui/components/layout/HBox';

export const TextElement = ({ name, hideLabels, onChange, ...remainingProps }) => {
	const [parsedName, setParsedName] = useState();

	const handleChange = (event) => {
		const name = `{${event.currentTarget.value}}`;
		setParsedName(name);
	};

	const handleBlur = (event) => {
		onChange(event.target.value);
	};

	// prettier-ignore
	return (
		<HBox className="TextElement">
			<input type="hidden" name={`${name}[name]`} defaultValue={parsedName} />

			<TextInput 
				label={!hideLabels ? 'Name' : null} 
				onChange={handleChange} 
				onBlur={handleBlur} 
				className="TextElement-Name" />

			<AutoComplete
				name={`${name}[defaultValue]`}
				label={!hideLabels ? 'Default value' : null}
				{...remainingProps}
				className="TextElement-Default"
			/>
		</HBox>
	);
};
