import React, { useState } from 'react';
import { TextInput } from 'ui/components/form/TextInput';
import { HBox } from 'ui/components/layout/HBox';

export const DateElement = ({ name, hideLabels, onChange }) => {
	const [parsedName, setParsedName] = useState();

	const handleChange = (event) => {
		const name = `{${event.currentTarget.value}}`;
		setParsedName(name);
	};

	const handleBlur = (event) => {
		onChange(event.target.value);
	};

	// prettier-ignore
	return (
		<HBox className="DateElement">
			<input type="hidden" name={`${name}[name]`} defaultValue={parsedName} />
			
			<TextInput 
				label={!hideLabels ? 'Name' : null} 
				onChange={handleChange}
				onBlur={handleBlur} 
				className="DateElement-Name" />

			<TextInput 
				name={`${name}[defaultValue]`} 
				label={!hideLabels ? 'Default value' : null} 
				className="DateElement-Default" />
		</HBox>
	);
};
