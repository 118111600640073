import React, { Children, cloneElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import { VBox } from '../layout';
import { Alert, Label } from '../typo';
import { ChevronDown } from '../icons/ChevronDown';
import { useClickOutside, useEscKey } from '../../hooks';
import { noop } from 'ui/helpers';

export const Select = ({ children: options, label, name = 'select', onSelect = noop, className, error, ...remainingProps }) => {
	const selectRef = useClickOutside(() => setIsOpen(false));
	useEscKey(() => setIsOpen(false));

	const [isOpen, setIsOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState();
	const [selectedOption, setSelectedOption] = useState();
	const [errorMessage, setErrorMessage] = useState(error);

	useEffect(() => {
		const option = Children.toArray(options).find((option) => option.props.selected);

        setSelectedOption(option?.props?.children);
		setSelectedValue(option?.props?.value);
	}, []);

	useEffect(() => {
		setErrorMessage(error);
	}, [error]);

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = (value) => {
		const option = Children.toArray(options).find((option) => option.props.value === value);

        setSelectedOption(option.props.children);
		setSelectedValue(option.props.value);
        
		setIsOpen(false);
		onSelect(option.props.value);
	};

	const selectProps = {
		ref: selectRef,
		className: classnames('Select', { 'Select--isOpen': isOpen }, { 'Select--hasError': errorMessage }),
		...remainingProps,
	};

	const animProps = {
		initial: 'close',
		animate: isOpen ? 'open' : 'close',
	};

	const arrowAnimProps = {
		variants: {
			close: { rotate: 0 },
			open: { rotate: 180 },
		},
	};

	const optionsAnimProps = {
		variants: {
			close: { y: -30 },
			open: { y: 0 },
		},
	};

	// prettier-ignore
	return (
		<VBox className={className}>
			{label && <Label>{label}</Label>}

			<motion.div tabIndex="0" {...selectProps} {...animProps}>
				<input type="hidden" name={name} defaultValue={selectedValue}/>

				<span onClick={handleClick} className="Select-SelectedValue">
					{selectedOption}
					<ChevronDown {...arrowAnimProps} />
				</span>

				<motion.div {...optionsAnimProps} className="Select-Options">
					{Children.map(options, (option) =>
						cloneElement(option, {
							selected: option.props.value === selectedValue,
							onClick: handleSelect,
						}),
					)}
				</motion.div>
			</motion.div>

			{errorMessage && <Alert>{errorMessage}</Alert>}
		</VBox>
	);
};

Select.Option = ({ value, children, selected, onClick, ...remainingProps }) => {
	const optionProps = {
		onClick: () => onClick(value),
		className: classnames('Option', {
			'Option--selected': selected,
		}),
		...remainingProps,
	};

	return <div {...optionProps}>{children}</div>;
};
