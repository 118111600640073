import React, { useContext } from 'react';

import { MetaDataContext } from '~/contexts/MetaDataContext';

import { AutoComplete } from 'ui/components/form/AutoComplete';
import { Panel } from 'ui/components/layout/Panel';
import {TagInput} from "ui/components/form/TagInput";

export const Sidecar = () => {
	const { items } = useContext(MetaDataContext);

	return (
		<Panel className="Sidecar">
			<h1>Sidecars</h1>
			<AutoComplete name="iconikSidecarElements" suggestions={items} label="Iconik elements" />

			<TagInput name="iconikSidecarExtensions" label="Iconik extensions" isFile={true}/>

			<TagInput name="iconikSidecarDestinations" label="Iconik destinations" />
		</Panel>
	);
};
