import React, { useContext } from 'react';

import { MetaDataContext } from '~/contexts/MetaDataContext';

import { AutoComplete } from 'ui/components/form/AutoComplete';
import { Select } from 'ui/components/form/Select';
import { TagInput } from 'ui/components/form/TagInput';
import { HBox } from 'ui/components/layout/HBox';
import { Panel } from 'ui/components/layout/Panel';

// prettier-ignore
export const Settings = () => {
    const { items } = useContext(MetaDataContext);
    
    return (
	<Panel className="Settings">
		<h1>Organize settings</h1>

		<HBox>
            <Select name="copyFilesOption" className="Settings-CopyFilesOptions">
                <Select.Option value="doNotCopyFiles" selected>Do not copy files</Select.Option>
                <Select.Option value="copyOnlyFiles">Copy only files</Select.Option>
            </Select>

            <TagInput name="copyFiles" className="Settings-CopyFilesInput" isFile={true} />
        </HBox>

        <TagInput name="ignoreBundles" label="Ignore bundles" />

        <Select name="ignoreEmptyFolders" label="Ignore folders" className="Settings-IgnoreFolders">
            <Select.Option value="off" selected>Off</Select.Option>
            <Select.Option value="ignoreEmptyFolders">When empty</Select.Option>
            <Select.Option value="flattenFolders">All (Flatten folders)</Select.Option>
        </Select>

        <AutoComplete name="labelPattern" suggestions={items} label="Auto label" />
        <AutoComplete name="renamePattern" suggestions={items} label="Rename files" />
        <AutoComplete name="folderPattern" suggestions={items} label="Create folders" />
	</Panel>
)};
